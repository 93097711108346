*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: rgba(128, 128, 128, 0.2);
}

*::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.4);
  border-radius: 4px;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

#vcs_map_container {
  /* position: absolute; */
  /* height: calc(100vh - 64px); */
  /* margin-top: 64px; */

  position: relative;
  height: calc(100vh - 112px);
  width: 100%;

  /* border: 1px solid #ccc; */
  /* border-radius: 5px; */
}

.mapElement,
.mapElement .cesium-widget,
.mapElement .cesium-widget canvas {
  width: 100%;
  height: 100%;
}
